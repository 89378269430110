import * as client_hooks from '../../../../_commons/hooks-client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/(app)": [10,[2],[3]],
		"/(open)/audit/[companyId]/[requestId]": [~40,[7,8]],
		"/(open)/campagnes/[...firmId]": [41,[7]],
		"/(open)/cgu": [~42,[7]],
		"/(auth)/email/verify": [~34],
		"/(auth)/info": [~35],
		"/(app)/newsletters/history": [~32,[2],[3]],
		"/(open)/newsletters/[id]": [~43,[7]],
		"/(auth)/password/forgot": [36],
		"/(auth)/password/reset": [37],
		"/(open)/potential/[companyId]": [~44,[7,9]],
		"/(app)/settings/preferences": [~33,[2],[3]],
		"/(auth)/signin": [~38],
		"/(auth)/signup": [~39],
		"/(app)/[companyId=guid]/aides": [~12,[2,4],[3,5]],
		"/(app)/[companyId=guid]/aides/[slug]": [~13,[2,4],[3,5]],
		"/(app)/[companyId=guid]/audit/[requestId]": [~14,[2,4],[3,5]],
		"/(app)/[companyId=guid]/files": [~15,[2,4],[3,5]],
		"/(app)/[companyId=guid]/newsletters/[...newsletterId]/results/[newsletterResultId]": [~17,[2,4],[3,5]],
		"/(app)/[companyId=guid]/newsletters/[...newsletterId]": [~16,[2,4],[3,5]],
		"/(app)/[companyId=guid]/offers": [18,[2,4],[3,5]],
		"/(app)/[companyId=guid]/potential": [~19,[2,4],[3,5]],
		"/(app)/[companyId=guid]/rapport-eligibilite/[id]": [~20,[2,4],[3,5]],
		"/(app)/[companyId=guid]/reports": [~21,[2,4],[3,5]],
		"/(app)/[companyId=guid]/settings": [~22,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/account": [23,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/billing": [~24,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/billing/invoices": [~25,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/billing/methods": [~26,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/billing/preferences": [~27,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/company": [28,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/security": [29,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/settings/team": [~30,[2,4,6],[3,5]],
		"/(app)/[companyId=guid]/subscribe": [31,[2,4],[3,5]],
		"/(app)/[companyId=guid]/[...requestId]": [~11,[2,4],[3,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';